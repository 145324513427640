






































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import HeaderBox from '@/components/HeaderBox.vue'
import UploadImage from '@/components/UploadImage.vue'
import auth from '@/common/auth'
import util from '@/common/util'

interface SearchParams {
  car_id: string
  store_name: string
  plate_number: string
  tmpl_id: number
  auction_from: string
  auction_to: string
  need_approval: any
  car_status: string
  receiver_name: string
  in_stock_from: string
  in_stock_to: string
  approval_name: string
  stock_status: string
  auction_time: any
  in_stock_time: any
  page: number
  count: number
}
interface TableData {
  stock_id: number
  car_id: string
  car_model: string
  plate_number: string
  store_name: string
  in_stock_time: string
  out_stock_time: string
  picker: string
  collector: string
  reviewer: string
  car_status: string
  auction_count: number
  need_approval: any
}
interface CarStatus {
  name: string
  value: string
}
interface WarehouseOutForm {
  center_car_id: string
  outbound_type: number
  image_urls: string[]
  consignee_id: any
  remark: string
}
@Component({
  components: {
    HeaderBox,
    UploadImage
  },
  filters: {
    // PENDING = 1  # 待开拍
    // BIDDING = 2  # 拍卖中
    // SOLD = 3  # 已拍出
    // UNSOLD = 4  # 已经流拍
    // COMPLETED = 5  # 已成交
    // CLOSED = 6  # 已关闭(拍出后交易失败)
    // DELETED = 7  # 已下架
    // APPROVING = 8  # 待审核
    // REJECTED = 9  # 已驳回 
    formatStatus (val) {
      return ['待开拍', '竞拍中', '已拍出', '已流拍', '已成交', '已关闭', '已下架', '待审核', '已驳回'][+val-1]
    }
  }
})
export default class StockManage extends Vue {
  loading: boolean = false
  tableData: TableData[] = []
  // uploadUrl: string = '/api/v1/source_handler/upload/image/outbound'
  searchParams: SearchParams = {
    car_id: '',
    store_name: '', // 经销商
    plate_number: '', // 车牌号
    tmpl_id: 0, // 开拍场次
    auction_from: '', // 开拍起始时间
    auction_to: '', // 开拍截止时间
    need_approval: 0, // 审核状态（0: 全部 1：是 2：否）
    car_status: '', // 车辆状态
    receiver_name: '', // 接车人名字
    in_stock_from: '', // 入库开始时间
    in_stock_to: '', // 入库截止时间
    approval_name: '', // 入库审核人
    stock_status: '0', // 库存状态（0： 全部 1：在库 2：出库）
    auction_time: '',
    in_stock_time: '',
    page: 1,
    count: 10,
  }
  carStatus: CarStatus[] = [
    {
      name: '全部车辆状态',
      value: ''
    },
    {
      name: '待审核',
      value: '8'
    },
    {
      name: '已驳回',
      value: '9'
    },
    {
      name: '待开拍',
      value: '1'
    },
    {
      name: '竞拍中',
      value: '2'
    },
    {
      name: '已拍出',
      value: '3'
    },
    {
      name: '已流拍',
      value: '4'
    },
    {
      name: '已成交',
      value: '5'
    },
    {
      name: '已关闭',
      value: '6'
    },
    {
      name: '已下架',
      value: '7'
    },
  ]
  totalNum: number = 0
  warehouseOutVisible: boolean = false
  warehouseOutForm = {
    type: 1,
    center_car_id: '', // 车辆id
    trace_id: '',
    outbound_type: 1, // 出库方式 1:交车出库 2：其它出库 3：退回门店
    image_urls: [],
    consignee_id: '', // 委托人id（交车出库才有此字段）
    consingee: {},
    remark: ''
  }
  rules = {
    image_urls: {
      required: true,
      message: '请上传图片',
      trigger: 'blur'
    },
  }
  outStock = {}
  consigneeList = []
  currentMobile = ''
  consignee = null
  sessionTmpl = []
  outboundBack: any = {}
  activeOutboundType = 1
  center_car_id = null
  hasActive = false
  isTapDone = false

  created() {
    this.getStockList();
    this.getSessionTmpl();
  }

  setTime(value, key) {
    if (value) {
      this.searchParams[`${key}_from`] = value && value[0];
      this.searchParams[`${key}_to`] = value && value[1];
    } else {
      this.searchParams[`${key}_from`] = this.searchParams[`${key}_to`] = ''
    }
  }

  async getConsignees (mobile) {
    const { code, data } = await this.$rest.carManage.getConsignees(mobile);
    if (code !== 0 ) return;
    this.consigneeList = data.infos;
  }

  async getStockList () {
    this.loading = true;
    const { code, data } = await this.$rest.carManage.getStockList(this.searchParams);
    this.loading = false;
    if (code === 0) {
      this.tableData = data.infos;
      this.totalNum = data.total_count;
    }
  }

  handleChangeConsignee (val) {
    this.consignee = this.consigneeList.filter(item => item.id === +val)[0];
  }

  handleDelConsignee () {
    this.warehouseOutForm.consignee_id = '';
    this.consignee = null;
  }

  handleTabClick (tab) {
    this.searchParams.stock_status = tab.name;
    this.getStockList();
  }

  handelSearch () {
    this.searchParams.page = 1;
    this.getStockList();
  }

  handleReset () {
    this.searchParams.page = 1;
    this.searchParams.car_id = ''
    this.searchParams.store_name = ''
    this.searchParams.plate_number = ''
    this.searchParams.tmpl_id = 0
    this.searchParams.auction_from = ''
    this.searchParams.auction_to = ''
    this.searchParams.need_approval = 0
    this.searchParams.car_status = ''
    this.searchParams.receiver_name = ''
    this.searchParams.in_stock_from = ''
    this.searchParams.in_stock_to = ''
    this.searchParams.approval_name = ''
    this.searchParams.stock_status = '0'
    this.searchParams.auction_time = ''
    this.searchParams.in_stock_time = ''
    this.getStockList();
  }

  handleCurrentChange () {
    this.getStockList();
  }

  handleCancel () {
    this.hasActive = false;
    this.warehouseOutVisible = false;
  }

  async getSessionTmpl () {
    const { code, data } = await this.$rest.carManage.getSessionTmpl();
    if (code !== 0) return;
    this.sessionTmpl = data;
  }

  async getOutStock (center_car_id, value = '') {
    this.center_car_id = center_car_id;
    const { code, data } = await this.$rest.carManage.getOutStock(center_car_id);
    if (code !== 0) return;
    if (this.hasActive) {
      if (!(+data.outbound_type === +value)) {
        this.clearStockOut();
        return;
      }
    }
    this.consignee = data.consingee ? this.consigneeList.filter(item => item.id === +data.consingee.id)[0] : null;
    this.outStock = data;
    this.warehouseOutForm.outbound_type = data.outbound_type;
    this.warehouseOutForm.remark = data.remark || '';
    this.warehouseOutForm.consignee_id = (data.consingee || {}).id;
    this.warehouseOutForm.image_urls = data.image_urls;
    this.warehouseOutForm.trace_id = data.trace_id;
  }

  async handleTypeChange (value) {
    (this.$refs['warehouseOutForm'] as any).resetFields();
    this.hasActive = true;
    this.warehouseOutForm.outbound_type = value;
    if (this.warehouseOutForm.type === 2) {
      await this.getOutStock(this.center_car_id, value);
    } else {
      this.clearStockOut();
    }
  }

  clearStockOut () {
    this.warehouseOutForm.image_urls = [];
    this.warehouseOutForm.consignee_id = '';
    this.warehouseOutForm.remark = '';
    this.consignee = null;
  }

  async handleStockOut (center_car_id, type, mobile = '') {
    this.consignee = null;
    this.consigneeList = []
    this.warehouseOutForm.center_car_id = center_car_id;
    this.warehouseOutForm.type = type;
    if (mobile) {
      this.getConsignees(mobile);
    }
    if (type === 1) {
      this.warehouseOutForm.outbound_type = 1;
      this.clearStockOut();
    } else {
      await this.getOutStock(center_car_id);
    }
    this.currentMobile = mobile;
    this.warehouseOutVisible = true;
  }

  handleGo (path, params) {
    const { href } = this.$router.resolve({ path, query: params });
    window.open(href, '_blank');
  }

  listenChildImage (urls) {
    this.warehouseOutForm.image_urls = urls;
  }

  validateParams() {
    let isValid = false
    const warehouseOutForm: any = this.$refs.warehouseOutForm
    warehouseOutForm.validate(res => {
      isValid = res
    })
    return isValid
  }

  async handleSave () {
    if (!this.validateParams()) return;
    let form: any = Object.assign({}, this.warehouseOutForm);
    if (+form.outbound_type === 3) {
      delete form.consignee_id;
    }
    this.isTapDone = true;
    form.image_urls = this.formatImages(form.image_urls);
    form = util.clearNullProp(Object.assign({}, form));
    let res = null;
    if (this.warehouseOutForm.type === 1) {
      res = await this.$rest.carManage.postOutStock(this.warehouseOutForm.center_car_id, form)
    } else {
      res = await this.$rest.carManage.updateOutStock(this.warehouseOutForm.center_car_id, form)
    }
    this.isTapDone = false;
    if (res.code === 0) {
      this.$message.success('保存成功');
      this.warehouseOutVisible = false;
      this.getStockList();
    }
  }

  get arrowUpLeft () {
    const left = [43, 230, 155];
    const outboundType = +this.warehouseOutForm.outbound_type;
    return `left:${left[outboundType - 1]}px`;
  }

  @Watch('warehouseOutVisible')
  showWarehouseOutVisible () {
    if (!this.warehouseOutVisible) {
      this.hasActive = false;
      (this.$refs['warehouseOutForm'] as any).resetFields();
      this.clearStockOut();
    }
  }
}
